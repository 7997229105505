import React, { createContext, useReducer, useContext } from "react";
import appStateReducer from "./appStateReducer";

export const AppStateContext = createContext({});
export const useAppStateContext = () => useContext(AppStateContext);
const initialValues = {
  selectedMatch: null,
  matches: [
    {
      matchNumber: 1,
      scores: [0, 0],
    },
    {
      matchNumber: 2,
      scores: [0, 0],
    },
    {
      matchNumber: 3,
      scores: [0, 0],
    },
  ],
};

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appStateReducer, initialValues);

  React.useEffect(() => {
    dispatch({
      actionType: "INIT",
    });
  }, []);

  return (
    <AppStateContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};
