import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "common-ui:back": "Back",
      "home.header": "Welcome to Crown Pickler",
      "home.title": "PICKLEBALL LIKE NEVER BEFORE!",
      "home.subtitle": "Crown Pickler brings you the ultimate pickleball experience! Compete for bragging rights! Enjoy flexible scheduling, automatic match assignment, and easy score tracking.",
      "home.flex.sched": "FLEXIBLE SCHEDULING OPTIONS",
      "home.flex.sched.desc": "Play at a time that suits your schedule. With Crown Pickler, you can configure the amount of time when a match needs to be played, ensuring that it can work with your busy life.",
      "more.games": "MORE GAMES GUARNTEED",
      "more.games.desc": "Get more pickleball with Crown Pickler! Guarnteed to play at least 15 games in leagues and at least 5 games in tournaments. More than likely it will be a lot more.",
      "stats": "STATS, STATS, AND MORE STATS",
      "stats.desc": "Discover your pickleball potential with Crown Pickler's tracking system! Our platform keeps track of all your games and stats, giving you a complete picture of your progress. With each game, your stats become more robust, allowing you to see head-to-head match-ups, league standings, and point leaders. Take your pickleball skills to the next level and keep track of your progress with Crown Pickler.",
      "home.subheader": "It’s pickleball in a whole new way!",
      "home.easy.create": "EASILY CREATE YOUR OWN LEAGUE OR TOURNAMENT",
      "home.easy.create.desc": "With Crown Pickler, you can set up a customized tournament or league in less than a minute. Invite your friends to join and let the app handle the rest - creating the schedule, setting match-ups, and collecting scores. Get started now!",
      "home.30-day.subtext": "Round Robin + King of the Court Tournaments.",
      "home.win.subtext": "Earn actual money with high finishes.",
      "home.play-anywhere": "Play Anywhere",
      "home.play-anywhere.subtext": "Play each match anywhere, anytime within a 3-day window.",
      "events.header": "Find an event near you or create your own",
      "events.team-format": "Team Format",
      "events.skill-level": "Skill Level",
      "events.find-tournaments": "Find Tournaments",
      "events.no-results-found": "No Results Found",
      "events.cant-find-an-event": "Can't find an event that works for you?",
      "events.create-your-own": "Create your own",
      "events.create-your-own-tournament": "Create your own tournament",
      "tournament-displayer.payout-type.value": "$30 dollar entry fee",
      "tournament-displayer.search-results": "Search results for {{zip}}",
      "tournament-displayer.max-teams": "{{maxTeams}} team max",
      "tournament-displayer.tournament.small-detail": "30-Day Round Robin & King's Court",
      "tournament-displayer.MensDouble": "Men's Doubles",
      "tournament-displayer.mens-singles": "Men's Singles",
      "tournament-displayer.spot-remaining_one": "{{count}} spot remaining",
      "match.match-number": "Match {{count}}",
      "match.submit": "Submit",
      "match.your-team": "Your Team",
      "match.opponent": "Opponent",
      "tournament-displayer.spot-remaining_other": "{{count}} spots remaining",
      "profile.my-tournaments": "My Tournaments",
      "profile.notifications": "Notifications",
      "profile.stats": "Player Ratings & Stats",
      "profile.account-settings": "Account Settings",
      "profile.support.how-it-works": "How Crown Pickler Works",
      "profile.support.faqs": "FAQ's",
      "profile.support.contact-us": "Contact Us",
      "profile.support.feedback": "Give Us Feedback",
      "profile.lebal.terms-of-service": "Terms of Service",
      "profile.support.privacy-policy": "Privacy Policy",
      "profile.account-settings.header": "Account Settings",
      "profile.contact-us.header": "Contact Us",
      "profile.faq.header": "FAQ",
      "profile.payments-and-payouts": "Payments & Payouts",
      "profile.faq.feedback": "Feedback",
      "profile.how-it-works.header": "How it works",
      "profile.notifications.header": "Notifications",
      "profile.payments-and-payouts.header": "Payments & Payouts",
      "profile.payments-and-payouts.value.header": "Value",
      "profile.payments-and-payouts.fee": "Entry Fee:",
      "profile.payments-and-payouts.standard.header": "Standard",
      "profile.payments-and-payouts.high-stakes.header": "High Stakes",
      "profile.payments-and-payouts.go-big-or-go-home.header": "Go Big or Go Home",
      "profile.payments-and-payouts.first-place": "1st Place",
      "profile.payments-and-payouts.second-place": "2nd Place",
      "profile.payments-and-payouts.third-place": "3rd Place",
      "profile.privacy-policy.header": "Privacy Policy",
      "profile.stats.header": "Rankings & Stats",
      "profile.terms-of-service.header": "Terms of Service",
      "profile.my-tournaments.header": "My Tournaments",
      "profile.my-tournaments.in-progress": "In Progress",
      "create-account.header": "Create Account",
      "download-app": "Get Started!",
      "sign-up-for-free": "Sign Up for Free!",
      "homepage-lets": "LET'S",
      "homepage-lets-play": "PLAY SOME PICKLEBALL!",
      "getstarted.install":"Install the Crown Pickler web app for free!",
      "getstarted.future":"Welcome to the future of apps!",
      "getstarted.future.info":"Crown Pickler is a web app that gives you all the benefits of a native app without the hassle of downloads, installations and updates. Unlike native apps, Crown Pickler is accessible from any device with an internet connection, making it easy to use and available whenever you need it.",
      "web-app-benefits.title":"Web App Benefits",
      "smaller-size.title":"Much smaller size",
      "smaller-size.body":"There are no downloads required, so it uses way less memory on your phone than apps from the App Store",
      "up-to-date.title":"Always up-to-date",
      "up-to-date.body":"You get the latest, greatest version of the app without ever having to download an update from the App Store",
      "ultra-secure.title":"Ultra Secure",
      "ultra-secure.body":"Your data is always secure, thanks to the latest—and always up-to-date—security protocols and encryption methods",
      "exact-same.title":"Exact same on every phone",
      "exact-same.body":"Whether you are using an Android or an iPhone, it is exactly the same. This makes it faster for us to get you the latest new updates and features"
      

    },
  },
  fr: {
    translation: {
      "tournament-displayer.max-teams": "{maxTeams} teams max",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
