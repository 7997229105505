import "./App.css";

import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/Home";
import GetStarted from "./pages/GetStarted";
import Navbar from "./component/Navbar";
import { AppStateProvider } from "./state/appStateProvider";
import { theme } from "./theme"

function App() {
  return (
      
        <MuiThemeProvider theme={theme}>
              <AppStateProvider>
                <CssBaseline />
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/get-started" element={<GetStarted />} />
                  </Routes>
                </BrowserRouter>
              </AppStateProvider>
        </MuiThemeProvider>
  );
}

export default App;
