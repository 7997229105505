export const getDeviceOS = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return 'iOS';
    } else if (/Android/.test(navigator.userAgent)) {
      return 'Android';
    } else if (/Chrome/.test(navigator.userAgent)) {
      return 'Chrome';
    } else {
      return 'unknown';
    }
};
