import {
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

const themeOptions = {
  card: {
    bg: "#1C1C1E",
  },
  actionButton: {
    fontSize: '1.6rem',
    fontWeight: 900,
    fontStyle: "italic",
    borderRadius: 40,
    padding: "2px 25px",
    borderWidth: "2px"
    
  },
  twoLineButton: {
    fontSize: '1.6rem',
    fontWeight: 900,
    fontStyle: "italic",
    borderRadius: 40,
    padding: "25px",
    paddingTop: "1px",
    paddingBottom: "5px",
    borderWidth: "2px",
    display: "inline-block"
  },
  typography: {
    allVariants: {
      fontFamily: "Inter, -apple-system, BlinkMacSystemFont, sans-serif",
    },
    h0: {
      color: "#DDFF00",
      fontWeight: "800",
      textAlign: "left",
      fontSize: "5rem",
      lineHeight: .9,
      display: "block",
    },
    h1: {
      color: "#DDFF00",
      fontWeight: "800",
      textAlign: "left",
      fontSize: "5rem",
      fontStyle: "italic",
      lineHeight: .9
    },
    h2: {
      color: "#DDFF00",
      fontWeight: "800",
      textAlign: "left",
      fontSize: "4rem",
      fontStyle: "italic",
      lineHeight: .9
    },
    h3: {
      textAlign: "left",
      fontWeight: "bold",
    },
    h4: {
      textAlign: "left",
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      color: "#DDFF00",
      fontWeight: "bold",
      lineHeight: "1",
    },
    h7: {
      color: "#DDFF00",
      fontWeight: "bold",
      lineHeight: "1",
    },
    body1: {
      fontWeight: "400",
      fontSize: "1rem",
    },
    body1bold: {
      fontWeight: "700",
      fontSize: ".9rem"
    },
    subtitle1: {
      color: "#999999",
    },
  },
  palette: {
    mode: "dark",
    customCard: {
      bg: "#1C1C1E",
    },
    nav: {
      bg: "#141415",
      activeItem: "#dce51b",
      inactiveItem: "#FFFFFF"
    },
    type: "dark",
    background: {
      default: "#1c1c1e",
    },
    primary: {
      main: "#dce51b",
    },
    secondary: {
      main: "#333333",
      contrastText: "#FFFFFF",
    },
    boxHeader: {
      bg: "#DCE51B",
      textColor: "#1C1C1E"
    }

  },
};

export const theme = responsiveFontSizes(createTheme(themeOptions));
export const symbolTheme = createTheme({
  typography: { fontFamily: ["Material+Symbols+Outlined"] }
});
