import * as React from "react";
import homeSlammer from "../images/homeSlammer-trim.png";
import cpPhone from "../images/cp-phone.png";
import cpStats from "../images/cp-stats.png";
import logo from "../images/crown_pickler_logo_full_large.png";
import simpleCircledCrown from "../images/simpleCircledCrown.png";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';

import {
  Container,
  Grid,
  Typography,
  Stack,
  Button
} from "@mui/material"
import DeviceType from "../component/DeviceType";

import step1Image from '../images/ios-step1.png';
import step2Image from '../images/ios-step2.png';
import step3Image from '../images/ios-step3.png';


function GetStarted() {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  // State to track the current step
  const [currentStep, setCurrentStep] = useState(1);

  // Handler for changing steps
  const changeStep = (step) => {
    setCurrentStep(step);
  };

  const titleStyle = {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '34px',
    color: '#DDFF00',
    letterSpacing: '-0.01em'
};

  const title2Style = {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '22px'
  };

  const bodyStyle = {
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '22px'
  };

  const bodyHeaderStyle = {
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#DDFF00'
  };

  const getCurrentStepImage = () => {
    switch(currentStep) {
      case 1:
        return step1Image;
      case 2:
        return step2Image;
      case 3:
        return step3Image;
      default:
        return step1Image; // Default to the first step if something goes wrong
    }
  };


  return (
    <Container maxWidth="md" sx={{ padding: '24px' }}>
    <Stack spacing={2}>
      <Typography sx={{ ...titleStyle }} align="left">{t("getstarted.install")}</Typography>
      {/* Grid container to center content horizontally */}
      <Grid container justifyContent="center">
        <Grid item>
          {/* No need for an additional div for centering the image */}
          <img 
            src={getCurrentStepImage()} 
            alt={`Step ${currentStep}`} 
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '500px' }} // Adjust max-height as needed
          />
        </Grid>
      </Grid>
      {/* Buttons for steps - centered using Grid */}
      <Grid container justifyContent="center">
  {[1, 2, 3].map((step) => (
    <Grid item key={step}> {/* Ensure each button is wrapped in a Grid item for proper spacing */}
      <button
        onClick={() => changeStep(step)}
        style={{
          display: 'inline-flex', // Use inline-flex instead of flex
          justifyContent: 'center',
          alignItems: 'center',
          width: '48px',
          height: '48px',
          maxWidth: '48px',
          maxHeight: '48px',
          borderRadius: '50%',
          margin: '5px',
          backgroundColor: currentStep === step ? '#DDFF00' : 'transparent',
          color: currentStep === step ? '#000000' : '#DDFF00',
          border: '2px solid #DDFF00',
          fontSize: '20px',
          fontWeight: 'bold',
          fontStyle: 'italic',
          boxSizing: 'border-box',
        }}>
            {step}
        </button>
        </Grid>
        ))}
    </Grid>


      <Typography  sx={{ ...titleStyle }} align="left">{t("getstarted.future")}</Typography>
      <Typography sx={{ ...bodyStyle }} align="left">{t("getstarted.future.info")}</Typography>
      <Typography sx={{ ...title2Style }} align="left">{t("web-app-benefits.title")}</Typography>
      <Stack spacing = {0}>      
        <Typography sx={{ ...bodyHeaderStyle }} align="left">{t("smaller-size.title")}</Typography>
        <Typography sx={{ ...bodyStyle }} align="left">{t("smaller-size.body")}</Typography>
      </Stack>

      <Stack spacing = {0}>
      <Typography sx={{ ...bodyHeaderStyle }} align="left">{t("up-to-date.title")}</Typography>
      <Typography sx={{ ...bodyStyle }} align="left">{t("up-to-date.body")}</Typography>
      </Stack>

      <Stack spacing = {0}>
      <Typography sx={{ ...bodyHeaderStyle }} align="left">{t("ultra-secure.title")}</Typography>
      <Typography sx={{ ...bodyStyle }} align="left">{t("ultra-secure.body")}</Typography>
      </Stack>

      <Stack spacing = {0}>
      <Typography sx={{ ...bodyHeaderStyle }} align="left">{t("exact-same.title")}</Typography>
      <Typography sx={{ ...bodyStyle }} align="left">{t("exact-same.body")}</Typography>
      </Stack>

      <div>
        @2024 Crown Pickler LLC | Pickleball Software
      </div>
      
      </Stack>
      


    
  </Container>
  );
}

export default GetStarted;
