export default function appStateReducer(state, action) {
  const actionsMap = {
    INIT: () => {
      return {
        ...state,
        selectedMatch: state?.matches[0],
      };
    },
    UPDATE_SELECTED_MATCH: () => {
      const match = state.matches.find((match) => {
        return match.matchNumber === action.value;
      });
      return {
        ...state,
        selectedMatch: match,
      };
    },
    UPDATE_MATCH_SCORE: () => {
      if (action.value.team === "self") {
        state.selectedMatch.scores[0] = action.value.score;
      }

      if (action.value.team === "opponent") {
        state.selectedMatch.scores[1] = action.value.score;
      }

      return {
        ...state,
        fred: action.value,
      };
    },
  };
  if (actionsMap[action.actionType]) {
    return actionsMap[action.actionType]();
  }

  return state;
}
