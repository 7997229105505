import * as React from "react";
import cpStats from "../images/StatsScreen.png";
import logo from "../images/crown_pickler_logo_full_large.png";
import appScreens from "../images/AppScreens.jpg";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowCircleRightRounded } from '@mui/icons-material';
import simpleCircleCrown from "../images/simpleCircledCrown.png";
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Stack,
  Button
} from "@mui/material"
import { getDeviceOS } from "../component/DeviceType";
import { fontSize } from "@mui/system";


function Home() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const os = getDeviceOS();

  const handleGetStartedClick = () => {
    if (os === 'iOS') {
      window.location.href = 'https://app.crownpickler.com/get-started'; // For iOS, navigate to '/get-started'
    } else {
      window.location.href = 'https://app.crownpickler.com'; // For Android, open the link
    }
  };

  const indicatorProgress = {
    borderRadius: "50%",
    background: theme.palette.primary.main,
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color:"#35381b"
    
    
  }

  return (
    <Container maxWidth="md" >
      <Stack spacing={2}>
      <Grid container alignItems="flex-end">
        <Grid xs={2}>
        <img
              
              src={appScreens}
              style={{ width: "400px", height: "auto", p:"0px", marginTop:"10px" }}
              alt=""
              

            />
            <img 
                src={simpleCircleCrown} 
                style={{ width: "60px", height: "60px", position: "absolute", top: "205px", zIndex: 1}}
                alt=""
            
            />
        
      </Grid>
        
      </Grid>
      <Typography variant="h1" sx={{fontWeight:"900"}} >{t("home.title")}</Typography>
      <Typography variant="h5" align="left">{t("home.subtitle")}</Typography>
      <Grid container justifyContent="center" sx={{display: "flex"}}>
        <Button 
          size="sm" 
          endIcon={<ArrowCircleRightRounded sx={{ fontSize: "3rem"}} />} 
          onClick={handleGetStartedClick}
          variant="contained" 
          sx={{ ...theme.actionButton, paddingRight: ".5rem", textAlign: "center" }}>
            {t("download-app")}
        </Button>
      </Grid>
      <div style={{ marginTop: '40px' }}>
        <div style={indicatorProgress}>
          <span class="material-symbols-outlined" style={{ fontSize: '2em' }}>
            today
          </span>
        </div>
      </div>
      <Typography variant="h2" align="left">{t("home.easy.create")}</Typography>
      <Typography variant="h5" align="left">{t("home.easy.create.desc")}</Typography>
      <div style={indicatorProgress}>
        <span class="material-symbols-outlined" style={{ fontSize: '2em' }}>
          pace
        </span>
      </div> 
      <Typography variant="h2" align="left">{t("home.flex.sched")}</Typography>
      <Typography variant="h5" align="left">{t("home.flex.sched.desc")}</Typography>
      <div style={indicatorProgress}>
        <span class="material-symbols-outlined" style={{ fontSize: '2em' }}>
          scoreboard
        </span>
      </div> 
      <Typography variant="h2" align="left">{t("more.games")}</Typography>
      <Typography variant="h5" align="left">{t("more.games.desc")}</Typography>
      <div style={indicatorProgress}>
        <span class="material-symbols-outlined" style={{ fontSize: '2em' }}>
          query_stats
        </span>
      </div> 
      <Typography variant="h2" align="left">{t("stats")}</Typography>
      <Typography variant="h5" align="left">{t("stats.desc")}</Typography> 
      <div>
      <img
            src={cpStats}
            alt="" 
            style={{ width: "400px", height: "auto", p:"0px", margin:"0px" }}
            
            />
      </div>  
      <div style={{marginTop: "-35px"}}>
      <Typography variant="h1" sx={{fontWeight:"900"}} >{t("homepage-lets")}</Typography>
      </div>
      <div style={{marginTop: "0px"}}>
      <Typography variant="h1" sx={{fontWeight:"900"}} >{t("homepage-lets-play")}</Typography>
      </div>
      <Grid container justifyContent="center">
        <div style={{padding: "30px"}}>
        <Button 
          size="sm" 
          component={Link} to={"https://app.crownpickler.com"} 
          variant="contained" 
          sx={{ ...theme.twoLineButton, paddingRight: ".5rem", width: "325px", height: "auto", textAlign: "center" }}
          
        >
            
          {t("sign-up-for-free")}
         
          <span style={{display: "block", fontSize: ".6rem", textTransform: "none", marginTop: "-7px", fontStyle: "normal"}}>Already a customer? Log in</span> 
        </Button>
        </div>
      </Grid> 
      <Grid container justifyContent="center">
        
      <img
            src={logo}
            alt="" 
            style={{ width: "90px", height: "auto" }}
            />
         </Grid> 
         <Grid container justifyContent="center" >
            <div style={{padding: "20px"}}>      
        @2024 Crown Pickler LLC | Pickleball Software
        </div>
      </Grid>
      
      </Stack>
    </Container>
  );
}

export default Home;
